import {Grid} from "@mui/material";
import "./Documentation.css"


function Documentation() {
    return (
        <Grid className={"head-text-style"} container>
            Coming soon...
        </Grid>
    );
}

export default Documentation;
